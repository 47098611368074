import Swal from "sweetalert2";
import './customAlert.css';
import ExpiredSession from "../../resources/images/expired-session.svg";
class Alert {
    cancelButtonColor = '#777';
    dangerButtonColor = '#cb2535';
    confirmButtonColor = '#21BA45';
    getSweetAlertQuestionSave = (description) => {
        return Swal.fire({
            title: 'Confirmar',
            html: description?description:`¿Está seguro que quiere guardar los cambios?`,
            icon: 'question',
            showCancelButton: true,
            confirmButtonColor: this.confirmButtonColor,
            cancelButtonColor: this.cancelButtonColor,
            confirmButtonText: 'Confirmar',
            cancelButtonText: 'Cancelar',
            reverseButtons: true,
        }).then((result) => {
            return result.value;
        });
    };

    getCustomAlert = (title, description, confirmButtonText, cancelButtonText) => {
        return Swal.fire({
            title: title,
            html: description,
            showCancelButton: true,
            confirmButtonColor: this.confirmButtonColor,
            confirmButtonText: confirmButtonText,
            cancelButtonColor: '#F9FAFB',
            cancelButtonText: cancelButtonText,
            customClass:{
                popup: 'my-popup-class',
                cancelButton: 'my-confirm-button-class'
            },
            reverseButtons: true
        }).then((result) => {
            return result.value;
        });
    };

    getSweetAlertCustom = (title, subtitle, content) => {
        return Swal.fire({
            title: title,
            html: `<h5>${subtitle}</h5> <br/> <p>${content}</p>`,
            confirmButtonColor: this.confirmButtonColor,
            confirmButtonText: 'Cerrar',
            reverseButtons: true,
        }).then((result) => {
            return result.value;
        });
    };

    getSweetAlertSuccess = (description) => {
        return Swal.fire({
            title: 'Excelente',
            html: description ? description : `Tu Cambio ha sido guardado correctamente`,
            icon: 'success',
            confirmButtonColor: this.confirmButtonColor,
            confirmButtonText: 'Cerrar',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            return result.value;
        });
    };
    getSweetAlertError = (description) => {
        return Swal.fire({
            title: 'Hubo un error!',
            html: description ? description : `No se ha podido realizar el cambio, intentalo nuevamente`,
            icon: 'error',
            confirmButtonColor: this.dangerButtonColor,
            confirmButtonText: 'Cerrar',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            return result.value;
        });
    };
    getSweetAlertWarning = (description, callback) => {
        return Swal.fire({
            title: 'Hubo un problema!',
            html: description ? description : `No se ha podido realizar el cambio, intentalo nuevamente`,
            icon: 'warning',
            confirmButtonColor: this.dangerButtonColor,
            confirmButtonText: 'Cerrar',
            allowOutsideClick: false,
            allowEscapeKey: false,
        }).then((result) => {
            if (callback) return callback(result);
            return result.value;
        });
    };
    getSweetAlertTop = (title, icon, text)=> {
        const toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
            text,
        });
        toast.fire({icon, title})
            .then((result) => {
                return result;
            });
    };
    checkHttpErrorResponse = (error) => {
        const title = 'Problemas para realizar la operación';
        const SwalCustom = Swal.mixin({
            title,
            icon: 'warning',
            allowOutsideClick: false,
            allowEscapeKey: false,
        })

        const SwalExpiredSession = Swal.mixin({
            imageUrl: ExpiredSession,
            imageWidth: 220,
            imageHeight: 220,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'Volver al inicio de sesión',
            confirmButtonColor: '#598392',
        });

        if (error?.response) {
            switch (error?.response?.status) {
                case 500 : {
                    if(error?.response?.data?.notifications[0]?.message === "Error: TypeError: Cannot read properties of null (reading 'individualResult')" || error?.response?.data?.notifications[0]?.message === "Error: TypeError: Cannot read property 'individualResult' of null") {
                    } else if(error?.response?.data?.notifications[0]?.message === 'TokenExpiredError: jwt expired' || error?.response?.data?.notifications[0]?.message === 'JsonWebTokenError: jwt malformed') {
                        SwalExpiredSession.fire({
                            html: `La sesión ha expirado y hemos cerrado tu sesión por seguridad, vuelve a iniciar sesión`,
                        }).then((result) => {
                            if (result.value) {
                                window.location.href = '/login';
                            }
                        });
                    } else {
                        SwalCustom.fire({
                            html: `<p>${error?.response?.data?.notifications[0]?.message}</p>`,
                        }).then((result) => {})
                    }

                    break;
                }
                case 510 : {
                    SwalCustom.fire({
                        html: `<p>${error?.response?.data?.message}</p>`,
                    });
                    break;
                }
                case 400 : {
                    SwalCustom.fire({
                        html: `<p>${error?.response?.data?.notifications[0].message}</p>`,
                    });
                    break;
                }
                case 403 : {
                    SwalCustom.fire({
                        html: `<p>${error?.response?.data?.notifications[0]?.message}</p>`,
                    }).then(
                        (result) => {
                            if (result.value) {
                                window.history.back()
                            }
                        }
                    );
                    break;
                }
                case 401 : {
                    SwalCustom.fire({
                        html: `<p>${error?.response?.data?.notifications[0]?.message}</p>`,
                    }).then(
                        (result) => {
                            if (result.value) {
                                window.location.href = '/';
                            }
                        }
                    );
                    break;
                }
                default : {
                    SwalCustom.fire({
                        html: `<p>No es posible conectarse con el servidor</p>`,
                        icon: 'error',
                    }).then(
                        (result) => {
                            if (result.value) {
                                localStorage.clear();
                                window.location.href = '/';
                            }
                        }
                    );
                }
            }
        } else {
            SwalCustom.fire({
                html: `<p>No es posible conectarse con el servidor</p>`,
                icon: 'error',
            }).then(
                (result) => {
                    if (result.value) {
                        localStorage.clear();
                        window.location.href = '/';
                    }
                }
            );
        }
    }
}

export default new Alert();
