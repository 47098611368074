import React from "react";
import { Grid, Header, Tab } from "semantic-ui-react";
import MyReferrals from "./MyReferrals";
import { Information } from "@carbon/icons-react";
import PolarisPopup from "../../shared/component/PolarisPopup";
import { ProgramBases } from "./ProgramBases";
import './Referrals.css';

const Referrals = () => {
  
    return (
        <Grid stackable className="container-referrals">
            <Grid.Row>
              <Header size='large' className='referrals-txt'>Referidos
                <PolarisPopup
                  className='popup-referrals-header'
                  wide='very'
                  children={<Information size={22} style={{ cursor: 'pointer', marginLeft: '.7em', color: 'var(--color-primary)' }}/>}
                  html={<p style={{ lineHeight: '21px' }}>En esta sección podrás participar del programa de referidos de la compañía, refiriendo talentos para las posiciones o vacantes disponibles, así como conocer las bases vigentes del programa y estar informado en todo momento acerca de cada etapa de los procesos de selección en los cuales tu referencia se encuentre participando.</p>}
                  position='right center'
                />
              </Header>
            </Grid.Row>
            <Grid.Row>
              <Tab menu={{secondary: true, pointing: true}} panes={[
                { 
                  menuItem: 'Mis Referidos',
                  render: () => <Tab.Pane attached={false}><MyReferrals /></Tab.Pane>,
                },
                { 
                  menuItem: 'Bases del programa',
                  render: () => <Tab.Pane attached={false}><ProgramBases /></Tab.Pane>,
                },
              ]}/>
            </Grid.Row>
        </Grid>
    );
}

export default Referrals;