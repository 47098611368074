
const CONSTANT = {
    auth: 'auth',
    refresh: 'refresh'
};

const setToken = (token) => {
    window.localStorage.setItem(CONSTANT.auth, JSON.stringify({token}));
}
const setRefreshToken = (token) => {
    window.localStorage.setItem(CONSTANT.refresh, JSON.stringify({token}));
}

const removeToken = () => {
    window.localStorage.removeItem(CONSTANT.auth);
}
const removeRefreshToken = () => {
    window.localStorage.removeItem(CONSTANT.refresh);
}

const getToken = (type) => {
    const item = window.localStorage.getItem(type);
    if (typeof item === undefined) return undefined;
    return JSON.parse(item)?.token;
}

export {
    setToken, getToken, removeToken, setRefreshToken, removeRefreshToken
}